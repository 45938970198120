// ---------------------------------------------------------

import dynamic from 'next/dynamic';

// ---------------------------------------------------------

const Accordion = dynamic(() => import('@components/accordion'));
const Container = dynamic(() => import('@layout/container'));

// ---------------------------------------------------------

import Category from '@components/category';
import AgentFinder from '@components/agent-finder';
import ArticleCard from '@components/article-card';
import BuysideWidget from '@components/buyside-widget';
import Callout from '@components/callout';
import Carousel from '@components/carousel';
import FeaturedContent from '@components/featured-content';
import Forms from '@components/forms';
import Image from '@components/image/block-image';
import Jumbotron from '@components/jumbotron';
import OfficeSearch from '@components/agent-finder/search-by-office';
import PostList from '@components/post-list';
import PostListWithFeatured from '@components/post-list-with-featured';
import Quad from '@components/quad';
import SellWithSibcy from '@components/sell-with-sibcy';
import TextBlock from '@components/text-block';
import TopicCarousel from '@components/topic-carousel';
import Video from '@components/video';
import Button from '@components/button';
import FeaturedListings from '@components/featured-listings';
import PeopleCard from '@components/people-card';

import { center_button } from './styles.module.scss';

// ---------------------------------------------------------

export default {
  Accordion: Accordion,
  AgentFinder: AgentFinder,
  ArticleCard: ArticleCard,
  BlockContactForm: Forms,
  BuysideWidget: BuysideWidget,
  CallOut: Callout,
  Carousel: Carousel,
  Category: Category,
  Community: FeaturedContent,
  Cta: (props) => (
    <div className={center_button}>
      <Button {...props} />
    </div>
  ),
  FeaturedPost: FeaturedContent,
  Forms: Forms,
  Grid: Container,
  Jumbotron: Jumbotron,
  ModuleContentBlock: TextBlock,
  ModuleImage: Image,
  ModuleQuad: Quad,
  ModuleVideo: Video,
  OfficeSearch: OfficeSearch,
  PostBlog: FeaturedContent,
  PostBlogCategory: Category,
  PostList: PostList,
  PostListWithFeatured: PostListWithFeatured,
  SellWithSibcy: SellWithSibcy,
  TopicCarousel: TopicCarousel,
  TopicPage: FeaturedContent,
  TopicRecord: FeaturedContent,
  BlockFeaturedListings: FeaturedListings,
  BlockPerson: PeopleCard
};
