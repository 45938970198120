/* eslint-disable react/prop-types */
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import classNames from 'classnames';
import { getSlug } from '@lib/utils';

// ---------------------------------------------------------

import PropertyCard from '@components/card';
import ArticleCard from '@components/article-card';
import Content from '@components/content';
import Icon from '@components/icon';
import Image from '@components/image';
import Link from '@components/link';

// ---------------------------------------------------------

import {
  Keyboard,
  Navigation,
  Pagination,
  Thumbs,
  Autoplay,
  Scrollbar,
  A11y
} from 'swiper/modules';

// ---------------------------------------------------------

import {
  card_padded_section,
  card_wrapper_section,
  card_overlay_section,
  card_details_section,
  person_image_section,
  text_content,
  quote_text,
  author_text,
  card_link,
  carousel_link,
  link_container,
  card_wrapper,
  card_image,
  carousel_header_content,
  contentful_wrapper,
  next_el,
  prev_el,
  carousel_heading,
  carousel_sub_header,
  carousel_container,
  carousel_heading_section,
  carousel_cards_container,
  inner_container,
  active,
  dot,
  dots,
  slide_item,
  one_slide
} from '../styles.module.scss';
import 'swiper/scss/keyboard';
import 'swiper/scss/navigation';
import 'swiper/scss/thumbs';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/scss/a11y';

// ---------------------------------------------------------

const getFiftyFiftyContent = (card) => {
  return (
    <div className={card_padded_section}>
      <div className={card_wrapper_section}>
        <div className={card_overlay_section} />
        <div className={card_details_section}>
          <div className={person_image_section}>
            {card.featuredImage && (
              <Image
                priority
                image={{ height: 225, src: card.featuredImage.url, width: 159 }}
                alt={card.featuredImage.title}
                sizes="(max-width: 720px) 100vw, 50vw"
              />
            )}
          </div>
          <div className={text_content}>
            <p className={quote_text}>{card.quote || card.title}</p>
            <div className={author_text}>
              {card.author || (card.description && <p>{card.author || card.description}</p>)}
              {card.title && <p>{card.title}</p>}
            </div>
            {card.linkLabel && card.linkUrl && (
              <Link aria-label={card.linkLabel} className={card_link} url={card.linkUrl}>
                {card.linkLabel}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const getFullWidthContent = (card) => {
  return card?.linkUrl ? (
    <Link url={card.linkUrl} className={link_container}>
      <div className={card_wrapper}>
        <div className={card_image}>
          <Image
            alt={card?.featuredImage?.title || ''}
            layout="fill"
            image={card?.featuredImage}
            sizes="(max-width: 720px) 100vw, 50vw"
          />
        </div>
        <div className={text_content}>
          {card.title && <h3>{card.title}</h3>}
          <p>{truncate(card.description || card.excerpt, { length: 256 })}</p>
          {card.linkLabel && (
            <span aria-label={card.linkLabel} className={carousel_link}>
              {card.linkLabel}
              {card.linkLabel && <Icon type="arrow" name={'small-right'} />}
            </span>
          )}
        </div>
      </div>
    </Link>
  ) : (
    <div className={card_wrapper}>
      <div className={card_image}>
        <Image
          alt={card?.featuredImage?.title || ''}
          layout="fill"
          objectFit="cover"
          image={{
            height: card?.featuredImage?.height,
            src: card?.featuredImage?.url,
            width: card?.featuredImage?.width
          }}
          sizes="(max-width: 720px) 100vw, 50vw"
        />
      </div>
      <div className={text_content}>
        {card?.title && <h2>{card?.title}</h2>}
        <p>{truncate(card?.description || card?.excerpt, { length: 256 })}</p>
        {card?.linkLabel && card?.linkUrl && (
          <Link aria-label={card?.linkLabel} className={carousel_link} url={card?.linkUrl}>
            {card?.linkLabel}
            {card?.linkLabel && <Icon type="arrow" name={'small-right'} />}
          </Link>
        )}
      </div>
    </div>
  );
};

const getTextOnlyContent = (card) => {
  return (
    <div className={text_content}>
      {card.description && (
        <>
          <h2>{card.title}</h2>
          <p>{card.description}</p>
          {card.linkLabel && card.linkUrl && (
            <Link aria-label={card.linkLabel} className={carousel_link} url={card.linkUrl}>
              {card.linkLabel}
              {card.linkLabel && <Icon type="arrow" name={'small-right'} />}
            </Link>
          )}
        </>
      )}
    </div>
  );
};

const getSlideContent = (card, theme) => {
  switch (theme) {
    case 'listings':
      return (
        <PropertyCard
          alertPills={card.alertPills}
          image={{
            src: !card.mainPhoto
              ? 'https://place-hold.it/340x231/green/white&text=FPO&fontsize=16'
              : `${card.mainPhoto.midSizeImageUrl}`
          }}
          property={{
            ...card,
            details: [
              { br: card.bedroomCount },
              {
                ba: card.partBathroomCount
                  ? `${card.fullBathroomCount}/${card.partBathroomCount}`
                  : card.fullBathroomCount
              },
              { ac: card.acres }
            ],
            price: card.priceFormatted,
            realtor: {
              isActiveSibcyAgent: !card.isIdxListing,
              primaryRealtorName: card.primaryAgentDisplayOne,
              secondaryRealtorName: card.secondaryAgentDisplayOne
            }
          }}
          url={getSlug(card.canonicalUrl)}
          key={`card-${card.listingId}`}
        />
      );
    case 'cards':
      return <ArticleCard {...card} />;
    case 'section':
      return getFiftyFiftyContent(card);
    case 'full-width':
      return getFullWidthContent(card);
    case 'ldp':
      return getFullWidthContent(card);
    case 'text-only':
      return getTextOnlyContent(card);
    default:
      return undefined;
  }
};

const TopicCarousel = (props) => {
  const {
    slidesCollection,
    carouselHeader,
    carouselDescription,
    theme,
    background,
    autoPlay,
    carouselId
  } = props;
  const uniqueId = carouselId;

  const swiperOptions = {
    listings: {
      breakpoints: {
        ...(slidesCollection?.cards?.length > 1 && {
          250: { slidesPerView: 1.15 },
          480: { slidesPerView: 1.2 },
          600: { slidesPerView: 1.5 },
          720: { slidesPerView: 2.2 },
          960: { slidesPerView: 3.2 }
        })
      },
      centeredSlides: false,
      initialSlide: 0,
      slidesPerView: 'auto',
      spaceBetween: 20,
      pagination: false,
      scrollbar: {
        draggable: true
      },
      loop: false
    },
    cards: {
      breakpoints: {
        ...(slidesCollection?.cards?.length > 1 && {
          250: { slidesPerView: 1.1 },
          480: { slidesPerView: 1.2 },
          600: { slidesPerView: 1.5 },
          720: { slidesPerView: 2.02 },
          960: { slidesPerView: 3.05 }
        })
      },
      centeredSlides: false,
      initialSlide: 0,
      slidesPerView: 'auto',
      spaceBetween: 20
    },
    'full-width': {
      breakpoints: {
        ...(slidesCollection?.cards?.length > 1 && {
          250: { slidesPerView: 1.1 },
          480: { slidesPerView: 1.2 },
          720: { slidesPerView: 1 }
        })
      },
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 20
    },
    ldp: {
      breakpoints: {
        250: { slidesPerView: slidesCollection?.cards?.length > 1 ? 1.2 : 1 },
        480: { slidesPerView: slidesCollection?.cards?.length > 1 ? 1.2 : 1 },
        720: { slidesPerView: 1 }
      },
      loop: slidesCollection?.cards?.length > 1,
      slidesPerView: 'auto',
      spaceBetween: 20
    },
    section: {
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 60
    },
    'text-only': {
      slidesPerView: 'auto',
      spaceBetween: 20
    }
  };

  const description =
    typeof carouselDescription === 'string' ? (
      <p className={carousel_header_content}>{carouselDescription}</p>
    ) : (
      <Content className={contentful_wrapper} content={carouselDescription} />
    );

  const getNavArrows = () => {
    if (theme === 'cards') {
      return (
        <>
          <div id={`prev-el${uniqueId}`} className={prev_el}>
            <Icon type="arrow" name="default-left" />
          </div>
          <div id={`next-el${uniqueId}`} className={next_el}>
            <Icon type="arrow" name="default-right" />
          </div>
        </>
      );
    }
  };

  const getTopHeader = () => {
    if (theme !== 'section' && (carouselHeader || carouselDescription))
      return (
        <div className={carousel_heading}>
          {carouselHeader && <h2>{carouselHeader}</h2>}
          {description && <div className={carousel_sub_header}>{description}</div>}
        </div>
      );
  };

  const getBottomHeader = () => {
    if (theme === 'section')
      return (
        <div className={carousel_heading_section}>
          <h2>{carouselHeader}</h2>
          {description}
        </div>
      );
  };

  return (
    <div
      className={classNames(carousel_container, {
        [one_slide]: slidesCollection?.cards?.length === 1
      })}
      data-theme={theme}
      data-background={background}
    >
      <div className={inner_container}>
        {getNavArrows()}
        {getTopHeader()}
        <div className={carousel_cards_container}>
          <Swiper
            modules={[Keyboard, Navigation, Pagination, Thumbs, Autoplay, Scrollbar, A11y]}
            style={{ maxWidth: '100vw' }}
            autoplay={autoPlay ? { delay: 5000 } : false}
            initialSlide={0}
            loop={true}
            allowTouchMove={slidesCollection?.cards?.length > 1}
            navigation={{
              nextEl: `#next-el${uniqueId}`,
              prevEl: `#prev-el${uniqueId}`
            }}
            pagination={{
              bulletActiveClass: active,
              bulletClass: dot,
              clickable: true,
              el: `.${dots}`,
              type: 'bullets'
            }}
            keyboard={{
              enabled: true
            }}
            {...swiperOptions[theme]}
          >
            {slidesCollection &&
              slidesCollection.cards.map((card, idx) => {
                return (
                  <SwiperSlide
                    className={slide_item}
                    id={`${theme}-${uniqueId}${idx}`}
                    key={`${theme}-${uniqueId}${idx}`}
                  >
                    {getSlideContent(card, theme)}
                  </SwiperSlide>
                );
              })}
            {slidesCollection?.cards?.length > 1 && (
              <div>
                <div className={dots} />
              </div>
            )}
          </Swiper>
          {getBottomHeader()}
        </div>
      </div>
    </div>
  );
};

TopicCarousel.propTypes = {
  theme: PropTypes.oneOf(['section', 'cards', 'full-width', 'ldp', 'text-only', 'listings'])
};

TopicCarousel.defaultProps = {
  autoPlay: false
};

export default TopicCarousel;
